<template>
  <div class="d-flex">
    <custom-multiselect
      v-model="selectValue"
      :list="selectValues"
      :class="{ invalid: invalid }"
      :disabled="disabled"
      class="select-input__select"
      :allowEmpty="false"
    >
      <template v-slot:block="props">
        <span>
          {{ props.value }}
        </span>
      </template>
      <template v-slot:list="props">
        <span>
          {{ props.value }}
        </span>
      </template>
    </custom-multiselect>
    <input
      :class="{ invalid: invalid }"
      class="select-input__input"
      type="text"
      :disabled="disabled"
      v-model="inputValue"
    />
  </div>
</template>
<script>
export default {
  name: "SelectInput",
  props: {
    value: {
      type: String,
      required: true,
    },
    selectValues: {
      type: Array,
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectValue: "https://",
      inputValue: "",
    };
  },
  watch: {
    selectValue: {
      immediate: true,
      handler() {
        this.$emit("input", this.selectValue.concat("", this.inputValue));
      },
    },
    inputValue: {
      immediate: true,
      handler() {
        this.selectValues.some((val) => {
          if (this.inputValue.includes(val)) {
            this.selectValue = val;
            this.inputValue = this.inputValue.replace(val, "");
          }
        });
        this.$emit("input", this.selectValue.concat("", this.inputValue));
      },
    },
    value() {
      if (this.value.length > 0) {
        let idx = this.selectValues.findIndex((item) =>
          this.value.includes(item)
        );
        this.inputValue = this.value.replace(this.selectValues[idx], "");
        this.selectValue = this.selectValues[idx];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-input {
  &__select {
    flex: 1;

    &::v-deep {
      .custom-multiselect-wrapper {
        min-height: 100%;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-width: 1px;
        border-color: rgba(50, 58, 70, 0.1);
      }
    }
  }

  &__input {
    width: 75%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-width: 1px;
    border-color: rgb(50 58 70 / 0.1);

    &:focus {
      box-shadow: none !important;
    }
  }
}

.invalid {
  &::v-deep {
    .multiselect__tags {
      border: 1px solid red;
      border-right: none;
    }
  }
}
</style>