<template>
  <div class="col-12 mt-3 px-0">
    <div class="form-item">
      <div class="form-item__field _overflow">
        <input
          type="text"
          :placeholder="$t('platform.add_tb_link')"
          :value="value"
          @input="handleInput"
          :class="{ invalid: invalid }"
        />
        <small v-show="invalid" class="error">
          {{ $t("platform.url_invalid") }}
        </small>
      </div>
      <div class="d-flex mt-4">
        <small class="add-macros">{{ $t("platform.add_macros") }}</small>
        <div class="macros-list">     
          <div
            class="macros"
            :class="{ active: value.includes(macros.value) }"
            @click="$emit('addMacros', macros, title)"
            v-for="(macros, index) in macroses"
            :key="index"
          >
            {{ macros.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/_macros.scss";

export default {
  props: {
    invalid: {
      type: Boolean,
      default: false,
    },
    value: String,
    macroses: {
      type: Array,
    },
    title: String,
  },
  data() {
    return {
      field: "",
    };
  },
  methods: {
    handleInput(e) {
      let value = e.target.value;
      if (value.includes("&amp;")) value = value.replace(/&amp;/g, "&");
      this.$emit("input", value);
    },
  },
};
</script>