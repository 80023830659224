<template>
  <div class="content content_in mb-5 mb-md-0">
    <div class="row">
      <div class="col-12 col-xl-7">
        <div class="title-row row justify-content-between">
          <div class="col-12 col-md-auto">
            <h1>
              {{ isEdit ? $t("platform.edit_platform") : $t("platform.create_new") }}
            </h1>
          </div>
        </div>
        <VideoGuide class="d-xl-none mb-2" :title="$t('platform.how_to_setup_guide')" :description="$t('platform.video')" :videoLink="siteVideoGuide" />
        <StepperBar :activeStep="creatingSiteActiveStep" :stepList="creatingSiteStepList" v-if="!isEdit" />
        <div class="sep _h10"></div>
        <div v-show="creatingSiteActiveStep === 1 || isEdit">
          <div class="row">
            <div class="col-12">
              <div class="form-item pb-0">
                <div class="form-item__label">
                  {{ $t("platform.create.name") }}
                </div>
                <div class="form-item__field">
                  <input ref="name" v-model.trim="platform.name" type="text" :class="{ invalid: $v.platform.name.$invalid && platform.name != '' }" />
                  <small v-if="$v.platform.name.$invalid && platform.name != '' && !$v.platform.name.minLength" class="error">
                    {{ $t("min_len_pass", { number: "3" }) }}
                  </small>
                  <small v-if="$v.platform.name.$invalid && !$v.platform.name.maxLength" class="error">
                    {{ $t("max_len_pass", { number: 52 }) }}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label">
                  {{ $t("platform.payment_model") }}
                </div>
                <div class="form-item__field">
                  <custom-multiselect
                    v-model="platform.payment_model"
                    :list="paymentOptions"
                    :allowEmpty="false"
                    :disabled="isEdit"
                    class="payment mb-3"
                    :class="{
                      disabled_cpl: !user.is_cpl_payment_model_allowed,
                      invalid: $v.platform.payment_model.$error,
                    }"
                  >
                    <template v-slot:block="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                    <template v-slot:list="props">
                      <span>{{ props.value.title }}</span>
                      <br />
                      <small class="hint" v-if="props.value.title === 'Revshare'">
                        {{ $t("platform.revshare_option") }}
                      </small>
                      <small class="hint" v-if="props.value.title === 'CPL'">
                        {{ user.is_cpl_payment_model_allowed ? $t("platform.cpl_option") : $t("platform.cpl_option_ask") }}
                      </small>
                    </template>
                  </custom-multiselect>
                  <small class="error" v-if="$v.platform.payment_model.$error">
                    {{ $t("platform.choose_paymet_model") }}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label">
                  {{ $t("platform.create.url") }}
                </div>
                <div class="form-item__field">
                  <SelectInput v-model.trim="platform.url" :selectValues="['https://', 'http://']" :disabled="isEdit" :invalid="$v.platform.url.$invalid && platform.url !== '' && platform.url !== 'http://' && platform.url !== 'https://'" />
                  <div class="warn-block mt-2" v-if="detectedBlogspot">
                    <svg class="icon" v-svg symbol="info" size="0 0 17 17" role="info"></svg>
                    <p>
                      {{ $t("platform.site.confirm_site.blogspot_warn") }}
                    </p>
                  </div>
                  <div class="warn-block mt-2" v-if="detectedWeebly">
                    <svg class="icon" v-svg symbol="info" size="0 0 17 17" role="info"></svg>
                    <p>
                      {{ $t("platform.site.confirm_site.weebly_warn") }}
                    </p>
                  </div>

                  <div class="warn-block mt-2" v-if="platform.url === 'http://'">
                    <svg class="icon" v-svg symbol="info" size="0 0 17 17" role="info"></svg>
                    <p>
                      {{ $t("platform.site.confirm_site.http_warn") }}
                    </p>
                  </div>
                  <small v-if="$v.platform.url.$invalid && !$v.platform.url.minLength && platform.url !== '' && platform.url !== 'http://' && platform.url !== 'https://'" class="error">
                    {{ $t("min_len_pass", { number: "3" }) }}
                  </small>
                  <small v-else-if="$v.platform.url.$invalid && !$v.platform.url.maxLength" class="error">
                    {{ $t("max_len_pass", { number: 126 }) }}
                  </small>
                  <small v-else-if="$v.platform.url.$invalid && !$v.platform.url.url && platform.url !== '' && platform.url !== 'http://' && platform.url !== 'https://'" class="error">
                    {{ $t("platform.url_invalid") }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="creatingSiteActiveStep === 2 || isEdit">
          <CheckSite class="row" :blogspot="detectedBlogspot" :status="checkSiteStatus" :type="checkType" :url="platform.url" @setType="setCheckType" @setStatus="setCheckSiteStatus" v-if="!isEdit" />
          <div class="row" v-if="platform.payment_model && platform.payment_model.title !== 'CPL'">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label">
                  {{ $t("platform.disable_show_ads") }}
                </div>
                <div class="form-item__field">
                  <custom-multiselect v-model="platform.disabledCategory" :list="disabledCategories" :class="{ invalid: allSelectedCategories }" :allowEmpty="true" :multiply="checkForMultiple" :searchBy="`name_${$i18n.locale === 'ru' ? 'ru' : 'en'}`">
                    <template v-slot:block="props">
                      <span>
                        {{ props.value[`name_${$i18n.locale === "ru" ? "ru" : "en"}`] }}
                      </span>
                    </template>
                    <template v-slot:list="props">
                      <span>
                        {{ props.value[`name_${$i18n.locale === "ru" ? "ru" : "en"}`] }}
                      </span>
                    </template>
                  </custom-multiselect>
                </div>
                <small v-if="allSelectedCategories" class="hint error-text">
                  <svg v-svg symbol="info" size="0 0 17 17" role="info"></svg>
                  {{ $t("platform.enable_category_error") }}</small
                >
              </div>
            </div>
          </div>
        </div>
        <div v-show="creatingSiteActiveStep === 3 || isEdit">
          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label">
                  {{ $t("platform.mailing_frequency") }}
                </div>
                <div class="form-item__field">
                  <custom-multiselect v-model="platform.mailing_frequency" :list="mailings" :allowEmpty="false">
                    <template v-slot:block="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                    <template v-slot:list="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                  </custom-multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label">
                  {{ $t("category") }}
                </div>
                <div class="form-item__field">
                  <custom-multiselect :disabled="isEdit" v-model="platform.category_id" :list="categories" :allowEmpty="false">
                    <template v-slot:block="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                    <template v-slot:list="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                  </custom-multiselect>
                </div>
              </div>
            </div>
            <div class="col-12">
              <CheckBoxWrapper v-model="platform.adult" :text="$t('platform.site.show_for_adult')" />
            </div>
            <div v-if="platform.payment_model && platform.payment_model.value === 0" class="col-12 col-md-auto pt-3">
              <CheckBoxWrapper v-model="platform.feedsSales" :text="$t('platform.sales_on_feeds')" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <div class="form-item">
                <div class="form-item__label info-popup">
                  {{ $t("platform.postback") }}
                  <span
                    v-tooltip="{
                      content: $t('platform.postback_pop_up'),
                      triggers: ['hover', 'click'],
                    }"
                  >
                    <svg v-svg symbol="info" size="0 0 17 17" role="info"></svg>
                  </span>
                </div>
                <div class="form-item__field mb-2">
                  <TraffbackWithMacros v-model="platform.postback_url" @addMacros="addMacros" :macroses="macroses" :invalid="$v.platform.postback_url.$invalid" :title="'postback_url'" />
                </div>
                <div class="form-item__field mb-2">
                  <div class="form-item__label">
                    {{ $t("platform.send_postback_to") }}
                  </div>

                  <custom-multiselect :list="postback_format_list" v-model="postback_format" :allowEmpty="true" :multiply="true">
                    <template v-slot:block="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                    <template v-slot:list="props">
                      <span>
                        {{ props.value.title }}
                      </span>
                    </template>
                  </custom-multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-1">
          <div class="col-12">
            <div class="form-item my-4 pb-0 site-button-wrapper">
              <button v-if="creatingSiteActiveStep !== 1 && !isEdit" @click.prevent="changeStep(creatingSiteActiveStep - 1)" class="btn _mob100 _bg-gray">
                <svg v-svg role="presentation" size="0 0 20 14" symbol="prev" />
                {{ $t("back") }}
              </button>
              <button v-if="creatingSiteActiveStep !== creatingSiteStepList.length && !isEdit" :disabled="disableNextButtonOnFirstStep || disableNextButtonOnSecondStep" @click.prevent="changeStep(creatingSiteActiveStep + 1)" class="btn _mob100">
                {{ $t("continue") }}
                <svg v-svg role="presentation" size="0 0 20 14" symbol="next" />
              </button>
              <button @click="createSite" :disabled="(checkSiteStatus !== 2 && !isEdit) || allSelectedCategories" v-if="creatingSiteActiveStep == creatingSiteStepList.length || isEdit" class="btn _mob100">
                {{ $t("save") }}
              </button>
              <button v-if="isEdit" @click.prevent="openModal" :disabled="allowButton" class="btn _bg-green _mob100">
                <svg v-svg role="presentation" size="0 0 20 13" symbol="coolicon" />
                {{ $t("platforms.table.get_code") }}
              </button>
            </div>
          </div>
        </div>
        <alert v-if="$alert.title" />
      </div>
      <div class="d-none d-xl-block col-xl-5">
        <VideoGuide :title="$t('platform.how_to_setup_guide')" :description="$t('platform.video')" :videoLink="siteVideoGuide" />
      </div>
    </div>
    <modal @closed="closeModal" :width="980" name="massModal" height="auto" :scrollable="true" classes="creative-modal overflow-removed" :shiftY="0">
      <PlatformModal :platform="siteInfo" @closeModal="closeModal" />
    </modal>
  </div>
</template>
<script>
import "@/assets/css/_page-platform.scss";
import "@/assets/css/_section.scss";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, url } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import TraffbackWithMacros from "@/components/TraffbackWithMacros.vue";
import addMacros from "@/mixins/addMacros";
import debounce from "@/mixins/debounce";
import ServiceSubAccs from "@/api/subaccsService";
import ServicePanel from "@/api/servicePanel";
import SelectInput from "@/components/SelectInput.vue";
import CheckSite from "@/components/CheckSite.vue";
import VideoGuide from "@/components/VideoGuide.vue";
import CheckBoxWrapper from "@/components/CheckBoxWrapper.vue";
import StepperBar from "@/components/StepperBar.vue";
import platformSettingsGetCode from "@/mixins/platformSettingsGetCode";

export default {
  name: "Site",
  mixins: [validationMixin, addMacros, debounce, platformSettingsGetCode],
  components: {
    CheckSite,
    TraffbackWithMacros,
    SelectInput,
    VideoGuide,
    CheckBoxWrapper,
    StepperBar,
  },
  data() {
    return {
      isEdit: false,
      currentPlatform: null,
      selectedPlatform: null,
      detectedBlogspot: false,
      detectedWeebly: false,

      createSiteType: null,

      dataLoaded: false,
      platform: {
        name: "",
        url: "https://",
        payment_model: {
          title: "Revshare",
          value: 0,
        },
        postback_url: "",
        disabledCategory: [],
        category_id: { title: "Adult", value: 1 },
        adult: true,
        feedsSales: false,
        mailing_frequency: { title: "Standard", value: "standart" },
      },
      disabledCategories: [],
      paymentOptions: [
        {
          title: "CPL",
          value: 1,
        },
        {
          title: "Revshare",
          value: 0,
        },
      ],
      checkSiteStatus: 0,
      checkType: 1,
      categories: [
        { title: "Adult", value: 1 },
        { title: "Mainstream", value: 2 },
      ],
      mailings: [
        { title: "Standard", value: "standart" },
        { title: "Maximum", value: "maximum" },
      ],

      creatingSiteStepList: [
        { id: 1, title: "platform.site.link", value: "link" },
        { id: 2, title: "platform.site.confirmation", value: "confirmation" },
        { id: 3, title: "platform.site.settings", value: "settings" },
      ],
      creatingSiteActiveStep: 1,
      postback_format: [],
      postback_format_list: [
        {
          title: "Push",
          id: 0,
          value: "push",
        },
        {
          title: "InPage",
          id: 1,
          value: "in_page",
        },
        {
          title: "OnClick",
          id: 2,
          value: "on_click",
        },
      ],

      platformID: null,
    };
  },
  watch: {
    "platform.url": function (val) {
      /(blogspot.com)/i.test(val) ? (this.detectedBlogspot = true) : (this.detectedBlogspot = false);
      /(weebly.com)/i.test(val) ? (this.detectedWeebly = true) : (this.detectedWeebly = false);
    },
    platform: {
      deep: true,
      handler() {
        if (this.platformID) {
          if (!this.debounceValue) {
            this.debounceValue = this.debounce(this.updatePlatformData, 1000);
          }
          this.debounceValue();
        }
      },
    },
    checkSiteStatus: {
      handler() {
        if (this.platformID) {
          if (!this.debounceValue) {
            this.debounceValue = this.debounce(this.updatePlatformData, 1000);
          }
          this.debounceValue();
        }
      },
    },
    checkType: {
      handler() {
        if (this.platformID) {
          if (!this.debounceValue) {
            this.debounceValue = this.debounce(this.updatePlatformData, 1000);
          }
          this.debounceValue();
        }
      },
    },
    postback_format: {
      handler() {
        if (this.platformID) {
          if (!this.debounceValue) {
            this.debounceValue = this.debounce(this.updatePlatformData, 1000);
          }
          this.debounceValue();
        }
      },
    },
  },
  validations: {
    platform: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(52),
      },
      url: {
        url,
        required,
        minLength: minLength(3),
        maxLength: maxLength(126),
      },
      postback_url: {
        url,
        maxLength: maxLength(4096),
      },
      payment_model: {
        required,
      },
    },
  },
  mounted() {
    if (this.$route.path.includes("site")) {
      this.createSiteType = "site";
    } else {
      this.createSiteType = "landing";
    }
    if (this.$route.fullPath.includes("edit-platform")) {
      this.isEdit = true;
    }
    this.creatingSiteActiveStep = Number(this.$route.params.step) || -1;

    this.selectedPlatform = this.$route.fullPath.includes("site") ? 1 : 2;

    if (this.$route.query.id) {
      this.platformID = +this.$route.query.id;
    }

    if (this.isEdit || this.$route.query.id) {
      const id = this.$route.params.id || this.$route.query.id;
      ServiceSubAccs.getSubAcc(id)
        .then((res) => {
          if (res && res.status === 200) {
            this.currentPlatform = res.data;
          }
        })
        .then(() => {
          this.getCategories().then(() => {
            if (this.currentPlatform) {
              this.platform = {
                name: this.currentPlatform.name,
                payment_model: this.currentPlatform.payment_model == 1 ? { title: "CPL", value: 1 } : { title: "Revshare", value: 0 },
                url: this.currentPlatform.url,
                disabledCategory: this.disabledCategories.filter((item) => this.currentPlatform.disabled_category_ids.includes(item.id)) || [],
                postback_url: this.currentPlatform.postback_url,
                category_id: this.categories.find((el) => el.value === this.currentPlatform.category_id),
                mailing_frequency: this.mailings.find((el) => el.value === this.currentPlatform.mailing_frequency) || this.platform.mailing_frequency,
                adult: Boolean(this.currentPlatform.adult),
                feedsSales: this.currentPlatform.deny_sell_to_feeds,
              };
              this.setPostbackValue();
              this.checkType = this.currentPlatform?.check_type || 1;
            }
          });
        })
        .finally(() => {
          if (this.currentPlatform.current_step) {
            this.creatingSiteActiveStep = this.currentPlatform.current_step;
            if (this.creatingSiteActiveStep == 3) {
              this.setCheckSiteStatus(2);
            }
            this.changeUrl();
          }
        });
    } else {
      this.getCategories();
    }
  },
  methods: {
    updatePlatformData(isFinish = false) {
      let params = {
        name: this.platform.name,
        disabled_category_ids: this.platform.disabledCategory.map((el) => el.id) || [],
        payment_model: +this.platform.payment_model.value,
        mailing_frequency: this.platform.mailing_frequency?.value || "standart",
        postback_url: this.platform.postback_url,
        adult: +this.platform.adult,
        deny_sell_to_feeds: this.platform.payment_model.value === 0 ? +this.platform.feedsSales : 0,
        enable_postback_in_page_revshare: this.checkPostbackValue("in_page"),
        enable_postback_on_click_revshare: this.checkPostbackValue("on_click"),
        enable_postback_push_revshare: this.checkPostbackValue("push"),
        check_type: this.checkType,
        url: this.platform.url,
        category_id: this.platform.category_id.value,
        site: this.selectedPlatform,
        current_step: +this.$route.params.step || 1,
      };
      if (isFinish) {
        params.current_step = null;
        ServiceSubAccs.finishSubAcc({
          id: this.platformID,
          params: params,
        }).then((res) => {
          if (res && res.status === 200) {
            this.$alert({
              title: this.$t("sent_success"),
              text: res.message,
              type: "success",
            });
            localStorage.setItem("current-format", "NativeWebPush");
            this.$router.push(`/platform-settings/${this.platformID}`);
            return;
          }
          this.$alert({
            title: this.$t("sent_error"),
            text: res.message,
          });
        });
        return;
      }
      if (this.platformID) {
        ServiceSubAccs.updateSubAcc({
          id: this.platformID,
          params: params,
        });
      } else {
        ServiceSubAccs.createSubAcc(params).then((res) => {
          if (res && res.status === 200) {
            this.platformID = res.data.id;
          }
        });
      }
    },
    changeUrl() {
      const platformType = this.$route.path.includes("site") ? "site" : "landing";
      if (+this.$route.params.step !== this.creatingSiteActiveStep) {
        this.$router.push({
          path: `/create-platform/${platformType}/${this.creatingSiteActiveStep}`,
          query: this.$route.query,
        });
      }
    },
    changeStep(step) {
      this.creatingSiteActiveStep = step;
      this.changeUrl();
      this.updatePlatformData();
    },
    async getCategories() {
      await ServicePanel.getCategories().then((res) => {
        if (res && res.data) {
          this.disabledCategories.push(...res.data);
          this.dataLoaded = true;
        }
      });
    },
    setPostbackValue() {
      const map = new Map([
        ["push", "enable_postback_push_revshare"],
        ["in_page", "enable_postback_in_page_revshare"],
        ["on_click", "enable_postback_on_click_revshare"],
      ]);

      map.forEach((value, key) => {
        if (this.currentPlatform[value]) {
          this.postback_format.push(this.postback_format_list.find((el) => el.value === key));
        }
      });
    },
    checkPostbackValue(format) {
      return this.postback_format.some((el) => el.value === format);
    },
    setCheckSiteStatus(val) {
      this.checkSiteStatus = val;
    },
    setCheckType(val) {
      this.checkType = val;
    },
    createSite() {
      if (this.checkSiteStatus !== 2 && !this.isEdit) {
        return;
      }
      this.$v.$touch();
      if (this.$v.$invalid || this.allSelectedCategories) {
        this.$gtm.trackEvent({ event: "create_source_disabled_button" });
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        return;
      }
      this.$gtm.trackEvent({ event: "create_source_submited" });
      let params = {
        name: this.platform.name,
        disabled_category_ids: this.platform.disabledCategory.map((el) => el.id) || [],
        payment_model: +this.platform.payment_model.value,
        mailing_frequency: this.platform.mailing_frequency?.value || "standart",
        postback_url: this.platform.postback_url,
        adult: +this.platform.adult,
        deny_sell_to_feeds: this.platform.payment_model.value === 0 ? +this.platform.feedsSales : 0,
        enable_postback_in_page_revshare: this.checkPostbackValue("in_page"),
        enable_postback_on_click_revshare: this.checkPostbackValue("on_click"),
        enable_postback_push_revshare: this.checkPostbackValue("push"),
      };
      let payload = {
        // general variables for platforms
        name: this.currentPlatform?.name,
        complexity: +this.currentPlatform?.complexity,
        tb_subscription: this.currentPlatform?.tb_subscription,
        tb_complexity: this.currentPlatform?.tb_complexity,
        tb_subscription_url: this.currentPlatform?.tb_subscription_url,
        tb_complexity_url: this.currentPlatform?.tb_complexity_url,
        mailing_frequency: this.currentPlatform?.mailing_frequency,
        adult: +this.currentPlatform?.adult,
        fullscreen: +this.currentPlatform?.fullscreen,
        lands: this.currentPlatform?.lands || [],
        postback_url: this.currentPlatform?.postback_url,
      };
      const id = this.$route.params.id || this.platformID;
      payload = Object.assign(payload, params);
      if (!this.$v.$invalid) {
        if (this.isEdit) {
          ServiceSubAccs.updateSubAcc({
            id: id,
            params: payload,
          }).then((res) => {
            if (res && res.status === 200) {
              this.$alert({
                title: this.$t("sent_success"),
                text: res.message,
                type: "success",
              });
              if (this.platformID) {
                localStorage.setItem("current-format", "NativeWebPush");
                this.$router.push(`/platform-settings/${this.platformID}`);
              }
              this.allowButton = false;
              return;
            }
            this.$alert({
              title: this.$t("sent_error"),
              text: res.message,
            });
          });
          return;
        } else {
          this.updatePlatformData(true);
        }
        this.$v.$reset();
      }

      return;
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    siteInfo() {
      if (!this.isEdit) return;
      return { ...this.currentPlatform, id: this.$route.params.id };
    },
    disableNextButtonOnFirstStep() {
      return this.creatingSiteActiveStep == 1 && (this.$v.platform.name.$invalid || this.$v.platform.url.$invalid);
    },
    disableNextButtonOnSecondStep() {
      return this.creatingSiteActiveStep == 2 && this.checkSiteStatus !== 2;
    },
    siteVideoGuide() {
      return this.$i18n.locale === "ru" ? "https://youtu.be/LDEEct4tJwg" : "https://youtu.be/6bAKJql9uRE";
    },
    allSelectedCategories() {
      if (this.platform.disabledCategory) return this.platform.disabledCategory[0]?.id === 0;
      else return false;
    },
    checkForMultiple() {
      if (this.dataLoaded && this.platform.disabledCategory && this.disabledCategories.length > 0 && this.platform.disabledCategory.length === this.disabledCategories.length) {
        // eslint-disable-next-line
        this.platform.disabledCategory = [{ name_en: "All", name_ru: "Все", id: 0 }];
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped lang="scss">
.warn-block {
  display: flex;
  align-items: start;
  column-gap: 6px;
  p {
    margin: 0;
    font-size: 14px;
    color: #c62424;
  }
  svg {
    width: 30px;
    fill: #c62424;
  }
}

.disabled_cpl {
  &::v-deep {
    .custom-multiselect-list .custom-multiselect-list__block:first-child {
      pointer-events: none;
      cursor: not-allowed;
      background: #ededed !important;
      color: #676767 !important;
    }
  }
}

.site-button-wrapper {
  display: flex;
  gap: 0 20px;
}
</style>
