import PlatformModal from "@/components/PlatformModal";
export default {
  components: { PlatformModal },
  data() {
    return {
      allowButton: true,
    };
  },
  methods: {
    closeModal() {
      this.$modal.hide("massModal");
    },
    openModal() {
      this.$modal.show("massModal");
    },
  },
};
