export default {
  data() {
    return {
      debounceTimer: null,
      debounceValue: null,
    };
  },
  methods: {
    debounce(func, timeout = 300) {
      return (...args) => {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          func.apply(this, args);
        }, timeout);
      };
    },
  },
};
