<template>
  <div>
    <div class="col-12">
      <h3 class="mb-4">
        {{ $t("platform.site.confirm_site.confirm_your_site") }}
      </h3>
      <ul class="tabs _mob100">
        <li class="flex-grow-1">
          <button :disabled="cloudflareDetected" class="w-100" :class="{ active: type === 1 }" @click="setType(1)">
            {{ $t("platform.site.confirm_site.txt_file") }}
          </button>
        </li>
        <li class="flex-grow-1">
          <button :disabled="cloudflareDetected" @click="setType(2)" class="w-100" :class="{ active: type === 2 }">
            {{ $t("platform.site.confirm_site.meta_tag") }}
          </button>
        </li>
        <li class="flex-grow-1">
          <button @click="setType(3)" class="w-100" :class="{ active: type === 3 }">
            {{ $t("platform.site.confirm_site.dns_txt") }}
          </button>
        </li>
      </ul>
      <div class="message__item icon-info _gray _bg-off" v-if="type === 3">
        <svg class="message-icon" v-svg symbol="info" size="0 0 17 17" role="info" />
        <p>
          {{ $t("platform.site.confirm_site.dns_warn") }}
        </p>
      </div>
      <div class="tubs-content pt-2">
        <div class="cloudflare-warning" v-if="cloudflareDetected && type !== 3">
          <svg v-svg symbol="info" size="0 0 17 17" role="info" />
          <p v-html="$t('platform.site.confirm_site.cloudflare_warning')"></p>
        </div>
        <div class="mt-2 form-item" v-if="cloudflareDetected && type == 3">
          <div class="form-item__label mb-4" v-html="$t('platform.site.confirm_site.put_dns_txt')"></div>
          <div class="form-item__field">
            <div class="form-item__field _copy">
              <input id="meta" type="text" readonly class="_green" v-model="useDNSTXT" />
              <button @click="copy" type="button" class="btn icon-copy">
                <svg v-svg symbol="copy" size="0 0 13 15" role="info" />
              </button>
            </div>
          </div>
          <p class="pt-3 mb-1">
            {{ $t("platform.site.confirm_site.check_dns_txt") }}
          </p>
        </div>

        <template v-if="!cloudflareDetected">
          <div v-if="type === 1" class="tubs-content__item active">
            <div class="form-item">
              <div class="form-item__label mb-4">
                {{ $t("platform.site.confirm_site.download_file_1") }}
                <span class="font-weight-bold">partners-house.txt</span>
                {{ $t("platform.site.confirm_site.download_file_2") }}
              </div>
              <div class="form-item__field">
                <button @click="downloadFile" class="btn _border _mob100">
                  <span class="icon-download mr-2"> <svg v-svg symbol="download" size="0 0 16 16" role="info" /></span>partners-house.txt
                </button>
              </div>
              <p class="pt-3 mb-1">
                {{ $t("platform.site.confirm_site.check_file") }}
              </p>
            </div>
          </div>
          <div v-else-if="type === 2" class="tubs-content__item">
            <div class="form-item">
              <div class="form-item__label mb-4">
                {{ $t("platform.site.confirm_site.put_meta_1") }}
                <span class="font-weight-bold">meta {{ $t("platform.site.confirm_site.tag") }}</span>
                {{ $t("platform.site.confirm_site.put_meta_2") }}
              </div>
              <div class="form-item__field">
                <div class="form-item__field _copy">
                  <input id="meta" type="text" readonly class="_green" v-model="useTag" />
                  <button @click="copy" type="button" class="btn icon-copy">
                    <svg v-svg symbol="copy" size="0 0 13 15" role="info" />
                  </button>
                </div>
              </div>
              <p class="pt-3 mb-1">
                {{ $t("platform.site.confirm_site.check_meta") }}
              </p>
            </div>
          </div>
          <div v-else-if="type === 3" class="tubs-content__item">
            <div class="form-item">
              <div class="form-item__label mb-4" v-html="$t('platform.site.confirm_site.put_dns_txt')"></div>
              <div class="form-item__field">
                <div class="form-item__field _copy">
                  <input id="meta" type="text" readonly class="_green" v-model="useDNSTXT" />
                  <button @click="copy" type="button" class="btn icon-copy">
                    <svg v-svg symbol="copy" size="0 0 13 15" role="info" />
                  </button>
                </div>
              </div>
              <p class="pt-3 mb-1">
                {{ $t("platform.site.confirm_site.check_dns_txt") }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="col-12 pb-4">
      <div class="row align-items-center">
        <div class="col-12 col-md-auto mb-4">
          <button @click="checkStatus" :disabled="!url.length || status === 4 || blogspot || weebly || buttonDisabled" class="btn _border _green _mob100">
            {{ $t("platform.site.confirm_site.check") }}
          </button>
        </div>

        <div class="col-12 col-md-auto mb-4">
          <div v-if="status === 1" class="status">
            <div class="status__label">{{ $t("platform.site.confirm_site.status") }}:</div>
            <div class="status__result">
              <div class="status__load">
                {{ $t("platform.site.confirm_site.checking") }}
              </div>
            </div>
          </div>
          <div v-else-if="status === 2" class="status">
            <div class="status__label">{{ $t("platform.site.confirm_site.status") }}:</div>
            <div class="status__result">
              <div class="status__confirmed">
                <span class="icon-confirm">
                  <svg v-svg symbol="confirm" size="0 0 22 22" role="info" />
                </span>
                {{ $t("platform.site.confirm_site.confirm") }}
              </div>
            </div>
          </div>
          <div v-else-if="status === 3 || status === 4" class="status">
            <div class="status__label">
              <span> {{ $t("platform.site.confirm_site.status") }}: </span>
            </div>
            <div class="status__result">
              <div class="status__error" :class="{ center: status === 4 }">
                <span class="icon-no-confirm">
                  <svg v-svg symbol="no-confirm" size="0 0 22 22" role="info" />
                </span>
                <span>
                  {{ $t(`platform.site.confirm_site.${status === 3 ? "no_confirm" : "max_try"}`) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <alert v-if="$alert.title" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import subaccsService from "@/api/subaccsService";

export default {
  name: "CheckSite",
  props: {
    url: {
      type: String,
      default: "",
    },
    blogspot: {
      type: Boolean,
      default: false,
    },
    weebly:{
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: 0,
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    dnsTxt: "",
    buttonDisabled: false,
    cloudflareDetected: false,
  }),
  mounted() {
    subaccsService.getDNS().then((res) => {
      if (res && res.status == 200) this.dnsTxt = res.data.code;
    });
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    useTag() {
      return `<meta confirm="partners-house-${this.user.id}"` + `/` + `>`;
    },
    useDNSTXT() {
      return `${this.dnsTxt}`;
    },
  },
  methods: {
    setType(val) {
      this.$emit("setStatus", 0);
      this.$emit("setType", val);
    },
    downloadFile() {
      subaccsService.getFile().then((res) => {
        if (res) {
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "partners-house.txt");
          document.body.appendChild(link);
          link.click();
        }
      });
    },
    checkStatus() {
      if (this.url.length) {
        this.buttonDisabled = true;
        const payload = {
          check_type: this.type,
          url: this.url,
        };
        this.$emit("setStatus", 1);
        subaccsService
          .checkSite(payload)
          .then((res) => {
            res && res.data?.cloudflare ? (this.cloudflareDetected = true) : (this.cloudflareDetected = false);
            if (res) {
              if (res.status === 400) {
                if (res.message.includes("10")) {
                  this.$emit("setStatus", 4);
                } else {
                  this.$emit("setStatus", 3);
                }
              } else if (res.status === 200) {
                this.$emit("setStatus", 2);
              }
            } else {
              this.$emit("setStatus", 3);
            }
          })
          .finally(() => {
            this.buttonDisabled = false;
          });
      }
    },
    copy() {
      this.$helper.copyCode("meta");
      this.$alert({
        type: "success",
        title: this.$t("ref_system.copied"),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.cloudflare-warning {
  display: flex;
  margin-bottom: 20px;
  flex-flow: column wrap;
  align-items: center;
  color: #b84e4e;
  background: #fffbfb;
  row-gap: 20px;
  padding: 30px 20px 20px;
  border-radius: 10px;
  p {
    line-height: 26px;
    max-width: 440px;
    text-align: center;
  }
  svg {
    height: 40px;
    width: 40px;
    fill: #b84e4e;
  }
}

.warn-text {
  color: #ff6a6a;
  padding-left: 10px;
}
._border {
  &:hover {
    svg {
      fill: #fff;
    }
  }
}

.message-icon {
  position: absolute;
  left: 0;
  fill: #9b9ba4;
}
</style>
